.model {
  margin-left: 10px;
  display: flex;
  margin-bottom: 10px;
}

.model-thumb {
  width: 5vw;
  height: 80vh;
}
.model-image {
  display: flex;
  margin-left: 10px;
}
.model-image img {
  width: 500px;
}

.model-thumb img {
  padding: 10px 10px;
}
.model-thumb img:hover {
  cursor: pointer;
  opacity: 0.8;
}

.model-details {
  margin-left: 20px;
  width: 50vw;
}
.model-details p span {
  color: #fa901f;
}
.model-rating {
  color: #fa901f;
  display: inline-block;
}
.model-mini-info {
  margin-left: 20px;
}
.model-buy {
  padding: 5px 35px;
  color: #ffffff;
  background-color: #fa901f;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}
.model-wishlist {
  display: inline;
  margin-left: 15px;
}
.model-wishlist:hover {
  cursor: pointer;
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  .model {
    display: block;
  }
  .model-thumb {
    height: 5vh;
    width: fit-content;
  }
  .model-image {
    position: relative;
    top: 50px;
  }
  .model-image img {
    width: 60vw;
  }
  .model-image img,
  .model-thumb {
    margin-left: auto;
    margin-right: auto;
  }
  .model-details {
    position: relative;
    top: 60px;
  }
}
