.bs-div {
  background-color: #f0f0f0;
}

.bs-div h3 {
  margin-left: 10px;
  padding-top: 5px;
}

.bs-container {
  position: relative;
  width: 98vw;
  display: flex;
  align-items: center;
  overflow: auto;
  padding-bottom: 10px;
}
.bs-container div {
  padding: 5px;
  border-radius: 5px;
  border: 1px #00000080 solid;
  margin: 0px 5px;
}
.bs-container div:first-child {
  margin-left: 5px;
}
.bs-container div:last-child {
  margin-right: 10px;
}

.bs-container div img {
  position: relative;
  border-radius: 10px;
}

.bs-container div span {
  background-color: #ffffff;
  bottom: 5px;
}
.bs-container div span h5 {
  margin-top: 10px;
}
.bs-container div span button {
  margin-bottom: 5px;
}
.bs-container div span button {
  border: none;
  border-radius: 5px;
  background-color: rgb(250, 144, 31);
  color: #ffffff;
  padding: 3px 5px;
}
.bs-container div span button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.bs-left {
  margin-left: 10px;
}
.bs-right {
  margin-right: 0px;
}

.bs-container::-webkit-scrollbar {
  height: 15px;
  padding-top: 10px;
}

/* Track */
.bs-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
.bs-container::-webkit-scrollbar-thumb {
  background: rgb(250, 144, 31);
  border-radius: 10px;
}

/* Handle on hover */
.bs-container::-webkit-scrollbar-thumb:hover {
  background: rgb(248, 155, 55);
  cursor: pointer;
}
