.background-div {
  background-color: #eeeeee !important;
  -ms-overflow-style: none !important;
  font-family: "Josefin Sans", sans-serif !important;
  height: 100vh;
}

/*Hide scrollbars from chrome, safari and firefox*/
.login-div::-webkit-scrollbar {
  display: none !important;
}

.login-nav {
  width: 100vw;
  height: 5rem;
  background-color: #fff;
  display: flex;
}
.login-nav img {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  padding: 5px;
}
.login-nav h2 {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 3em;
  width: fit-content;
  top: 5%;
}

.login-password-input,
.login-email-input {
  width: 30rem;
  padding: 10px;
  margin-bottom: 10px;
}

.login-button {
  padding: 5px;
  width: 40%;
  color: white;
  background-color: #fa901f;
  border: none;
  border-radius: 5px;
}
.login-button:hover,
.google-login:hover {
  cursor: pointer;
  opacity: 0.8;
}

.google-login {
  width: 100%;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 5px;
}
.login-forgot-password {
  margin-left: 10px;
}

.google-image img {
  display: inline;
  width: 25px;
  margin-right: 5px;
}

/* RESPONSIVE */
@media screen and (max-width: 700px) {
  .login-form {
    position: relative;
    background-color: #ffffff;
    padding: 0.5rem;
    width: 100vw;
    top: 10%;
  }
  .login-password-input,
  .login-email-input {
    width: 75vw;
    padding: 5px;
    margin-bottom: 5px;
  }
  .login-email {
    margin-bottom: 5px;
  }
}
