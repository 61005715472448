.my-hero {
  background-size: cover;
  width: 100vw;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .my-hero {
    height: 250px;
    background-size: center;
  }
}
