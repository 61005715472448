/*----------------------------------------*/
/*  default CSS
/*----------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,300i,400,400i,700,700i");

html,
body {
  height: 100%;
  margin: 0 !important;
  font-family: "Josefin Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #111111;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
  font-weight: 500;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: 14px;
  color: #585858;
  line-height: 1.6;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

.row {
  display: flex;
}

/*------------------
  Header section
---------------------*/

.header-top {
  padding: 18px 0 14px 0px;
}
.nav-img-name {
  position: relative;
  display: flex;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.nav-brand-name {
  width: fit-content;
}

.nav-logo {
  display: inline-block;
}

.header-search-form {
  width: 100%;
  position: relative;
}

.header-search-form input {
  width: 100%;
  height: 44px;
  font-size: 14px;
  border-radius: 50px;
  border: none;
  padding: 0 19px;
  background: #f0f0f0;
}

.header-search-form button {
  position: absolute;
  height: 100%;
  right: 18px;
  top: 0;
  font-size: 26px;
  color: #000;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.nav-cart-signin {
  display: block;
  margin-left: auto;
  width: fit-content;
}
.user-panel .up-item {
  display: inline-block;
  font-size: 14px;
}

.user-panel .up-item i {
  font-size: 22px;
}

.user-panel .up-item a {
  font-size: 14px;
  color: #000;
}

.user-panel .up-item:first-child {
  margin-left: auto;
}

.shopping-card {
  display: inline-block;
  margin-left: auto;
  position: relative;
  
}

.shopping-card span {
  position: absolute;
  top: -4px;
  left: 100%;
  height: 16px;
  min-width: 16px;
  color: #fff;
  font-size: 13px;
  background: #fa901f;
  text-align: center;
  border-radius: 30px;
  padding: 0 2px;
  margin-left: -7px;
}

.signin,
.shopping-cart {
  font-weight: bold;
}

.main-navbar {
  background: #282828;
}

.main-menu {
  list-style: none;
}

.main-menu li {
  display: inline-block;
  position: relative;
}

.main-menu li a {
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  margin-right: 50px;
  line-height: 1;
  padding: 17px 0;
  position: relative;
}

.main-menu li a .new {
  position: absolute;
  top: -8px;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  background: #fa901f;
  line-height: 1;
  text-transform: uppercase;
  left: calc(50% - 21px);
  padding: 5px 9px 1px;
  border-radius: 15px;
  width: 42px;
}

.main-menu li:hover .sub-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  color: rgb(204, 204, 204);
}

.main-menu .sub-menu {
  position: absolute;
  list-style: none;
  width: 220px;
  left: 0;
  top: 100%;
  padding: 20px 0;
  visibility: hidden;
  opacity: 0;
  margin-top: 50px;
  background: #fff;
  z-index: 99;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
}

.main-menu .sub-menu li {
  display: block;
}

.main-menu .sub-menu li a {
  display: block;
  color: #000;
  margin-right: 0;
  padding: 8px 20px;
}

.main-menu .sub-menu li a:hover {
  color: #fa901f;
}

/* ----------------
  HAMBURGER
---------------------*/
.hamburger,
.ham-hide {
  position: absolute;
  z-index: 1;
  display: none;
  right: -3rem;
  cursor: pointer;
}

/* ----------------
  Footer section
---------------------*/

.footer-section {
  background: #282828;
  padding-top: 60px;
  margin-top: 20px;
}

.footer-logo {
  padding-bottom: 60px;
}

.footer-widget {
  margin-bottom: 70px;
  overflow: hidden;
}

.footer-widget h2 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 45px;
}

.footer-widget p {
  color: #8f8f8f;
}

.footer-widget.about-widget p {
  margin-bottom: 50px;
  letter-spacing: -0.01em;
}

.footer-widget ul {
  list-style: none;
  float: left;
  margin-right: 37px;
}

.footer-widget ul:last-child {
  margin-right: 0;
}

.footer-widget ul li a {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  font-size: 14px;
  color: #8f8f8f;
  margin-bottom: 6px;
}

.footer-widget ul li a:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  left: 0;
  top: 8px;
  border: 1px solid #fa901f;
  border-radius: 50%;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.footer-widget ul li a:hover {
  color: #fff;
}

.footer-widget ul li a:hover:after {
  width: 7px;
  height: 7px;
  top: 6px;
  background: #fa901f;
}

.fw-latest-post-widget .lp-item {
  margin-bottom: 30px;
  display: block;
  overflow: hidden;
}

.fw-latest-post-widget .lp-thumb {
  width: 64px;
  height: 64px;
  float: left;
  margin-right: 22px;
}

.contact-widget .con-info span {
  float: left;
  color: #fa901f;
  margin-right: 15px;
  overflow: hidden;
}

.social-links-warp {
  border-top: 2px solid #3b3535;
  padding: 46px 0;
  text-align: center;
  margin: 0 !important;
}

.social-links a {
  margin-right: 60px;
  display: inline-block;
}

.social-links a:last-child {
  margin-right: 0;
}

.social-links a img {
  width: 30px;
  height: 30px;
  color: #d7d7d7;
  float: left;
  margin-right: 19px;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.social-links a span {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #9f9fa0;
  padding-top: 10px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.social-links a:hover span {
  color: #fff;
}

.user-profile-image,
.shopping-cart-image {
  width: 35px;
}

.up-item a {
  padding: 0px 10px;
}

/* ----------------
  Responsive
---------------------*/

@media (min-width: 1200px) {
  .container {
    max-width: 1175px;
  }
  .hamburger {
    display: none;
  }
  .nav-brand-name {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 1350px) {
  .hamburger {
    display: none;
  }
}

/* Medium screen : 992px. */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hamburger {
    display: none;
  }
  .hero-slider .slider-nav-warp {
    max-width: 930px;
  }
  .footer-widget ul {
    margin-right: 5px;
  }
  .social-links a {
    margin-right: 20px;
  }
}

/* Tablet :768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hamburger {
    display: none;
  }

  .header-search-form {
    margin-bottom: 15px;
  }
  .user-panel {
    text-align: center;
  }
  .main-menu {
    text-align: center;
  }
  .sub-menu {
    text-align: left;
  }
  .main-menu li a {
    margin-right: 30px;
  }
  .product-filter-menu {
    text-align: center;
  }
  .product-filter-menu li {
    margin: 0 5px 10px;
  }
  .social-links {
    text-align: center;
  }
  .social-links a {
    margin-right: 20px;
  }
  .social-links a span {
    display: none;
  }
  .cart-table,
  .checkout-cart,
  .product-thumbs {
    margin-bottom: 50px;
  }
}

/* Large Mobile :480px. */

@media only screen and (max-width: 767px) {
  /* side nav responsive design */
  .hamburger {
    display: block;
  }
  .ham-hide {
    display: none;
  }
  .container {
    box-shadow: 0px 1px #00000020;
    padding-bottom: 10px;
  }
  .nav-logo {
    margin-bottom: 10px;
  }
  .main-navbar {
    display: none;
    transition: 3s ease;
  }
  .main-menu {
    position: relative;
    display: none;
    transition: 3s ease;
    z-index: 3;
    text-align: center;
    display: block;
  }
  .main-menu li {
    display: block;
  }

  .main-menu li a {
    display: block;
    font-size: 16px;
    color: #ffffff;
  }
  .main-menu li a .new {
    position: absolute;
    top: -4px;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    background: #fa901f;
    line-height: 1;
    text-transform: uppercase;
    left: -50;
    padding: 5px 9px 1px;
    border-radius: 15px;
    width: 42px;
  }
  .main-menu .sub-menu {
    left: 25%;
  }
  .header-search-form {
    margin-bottom: 15px;
  }
  .user-panel {
    text-align: center;
  }

  .footer-widget ul {
    margin-right: 25px;
  }
  .social-links {
    text-align: center;
  }
  .social-links a {
    margin-right: 15px;
  }
  .social-links a span {
    display: none;
  }
  .cart-table,
  .checkout-cart,
  .product-thumbs {
    margin-bottom: 50px;
  }
  .cart-table .size-col h4,
  .cart-table table tr th.size-th,
  .cart-table table tr th.quy-th,
  .cart-table .quy-col {
    padding-right: 0;
    width: 70px;
  }
  .cart-table .quy-col {
    width: 80px;
  }
  .address-rb {
    text-align: left;
  }
  .map {
    position: relative;
    width: 100%;
    background: #ddd;
    height: 400px;
    margin-top: 70px;
  }
}

/* Small Mobile :320px. */

@media only screen and (max-width: 479px) {
  .hamburger {
    display: block;
  }
  .social-links {
    text-align: center;
  }
  .social-links a img {
    font-size: 20px;
    margin-right: 0;
  }
  .social-links a span {
    display: none;
  }
}
