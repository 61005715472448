.cart-navbar {
  display: flex;
  background-color: #f0f0f0;
  padding: 1rem;
}
.cart-name {
  margin-left: 10px;
}
.cart-cart-text {
  margin-left: 0.5rem;
  color: #b6b6b6;
  font-weight: bold;
  font-size: 30px;
  margin-top: auto;
  margin-bottom: auto;
}
.cart-container {
  width: fit-content;
  margin-top: 1rem;
  display: flex;
}
.cart-cards {
  width: 70%;
}
.cart-info {
  width: 300px;
  height: fit-content;
  margin-right: 1.5rem;
  margin-left: 2rem;
  border: 1px solid;
  border-radius: 15px;
  padding: 10px;
}
.cart-info input {
  margin-bottom: 10px;
  border: none;
  border-bottom: 0.5px solid #00000050;
}
.cart-info input:focus {
  outline: none;
  border-bottom: 3.5px solid #fa901f;
}
.cart-info p {
  display: inline;
  width: fit-content;
  cursor: pointer;
}
.cart-info p:hover {
  text-decoration: underline;
}
.cart-info button {
  margin-left: 0.5rem;
  border: none;
  border-radius: 15px;
  background-color: #fa901f;
  color: white;
  padding: 7px 15px;
  margin-left: auto;
}
.cart-info button:hover {
  cursor: pointer;
  opacity: 0.8;
}


/********************  
  RESPONSIVE
******************* */
@media only screen and (max-width: 1030px) {
  .cart-container {
    display: block;
    margin-right: 1rem;
  }
  .cart-cards {
    width: 100% ;
  }
  .cart-info {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
}